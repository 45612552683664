import React from 'react'
import { CircularProgress } from '@mui/material'

import { dom } from '@/utils/dom'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  disabled?: boolean
  variant?: 'black' | 'normal'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  loadingClass?: string
}

export const CustomButton = (props: Props) => {
  const {
    className,
    children,
    size = 'md',
    disabled: disbaled = false,
    loading = false,
    loadingClass = '!w-4 !h-4',
    variant = 'normal',
    onClick,
  } = props

  const computedSize = () => {
    switch (size) {
      case 'xs':
        return 'px-2 py-1 text-xs loading-w-4'
      case 'sm':
        return 'px-2 py-1 text-sm'
      case 'md':
        return 'px-4 py-1.5 text-base'
      case 'lg':
        return 'px-4 py-2 text-xl'
      case 'xl':
        return 'px-5 py-3 text-2xl'
      case '2xl':
        return 'px-6 py-4 text-3xl'
      default:
        return 'px-4 py-1.5 text-base'
    }
  }

  const computedVariant = () => {
    switch (variant) {
      case 'black':
        return '!shadow-none !bg-black !text-white hover:opacity-70'
      case 'normal':
        return `!shadow-[2px_4px_0px_#000000] active:!translate-x-offset active:!translate-y-offset 
        active:!shadow-none lg:hover:!shadow-none lg:hover:!translate-x-offset lg:hover:!translate-y-offset`
    }
  }

  return (
    <button
      style={{ border: '2px solid black' }}
      className={`
        !border-2 !border-black !rounded !transition-all 
        flex justify-center items-center bg-secondary
        ${computedSize()} ${computedVariant()} ${className} 
        ${dom.class(
          loading || disbaled,
          `opacity-40 pointer-events-none ${dom.class(loading, '!shadow-none')}`
        )}
      `}
      disabled={disbaled || loading}
      onClick={disbaled || loading ? undefined : onClick}
    >
      {loading ? (
        <CircularProgress
          size={14}
          className={`mr-2 ${loadingClass}`}
          color="info"
        ></CircularProgress>
      ) : null}
      {children}
    </button>
  )
}

export default CustomButton
