export const includePath = ['/chat']
export const whiteColorPages = ['/', '/ls', '/dc', '/about']

export const handleShowBg = (pathname: string) => {
  if (whiteColorPages.includes(pathname)) {
    return true
  }
  if (pathname !== '/') {
    return includePath.some((item) => pathname.startsWith(item))
  }
  return false
}
