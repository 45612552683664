export const dom = {
  scrollToBottom(el?: HTMLElement) {
    if (!window) return

    setTimeout(() => {
      if (el) {
        el.scrollTo(0, el.scrollHeight * el.scrollHeight)
      }
      const body = document.body

      window.scrollTo(0, body.scrollHeight * body.scrollHeight)
    })
  },

  class(condition: boolean, className: string) {
    return condition ? className : ''
  },
}
