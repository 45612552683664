export const usePlayer = () => {
  const play = (src: string, loop = true, volume = 0.6) => {
    const audio = new Audio(src)

    audio.loop = loop
    audio.volume = volume
    audio.play()

    return audio
  }

  return {
    playBgm: () => play('/audios/bgm.mp3'),
  }
}
