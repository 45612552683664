import { config } from '@/config/wagmi'
import { SignContext } from '@/contexts/sign-context'
import { hexadecimalEq } from '@/utils/address'
import { getSign, setLocalActiveSign } from '@/utils/sign'
import { getChainId } from '@wagmi/core'
import { t } from 'i18next'
import { useCallback, useContext, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useAccount, useConnect, useDisconnect, useSwitchChain } from 'wagmi'

import { injected } from 'wagmi/connectors'
import { useSignMsg } from './use-sign-msg'
import { useGlobalStore } from '@/store/global'

// 用户上次活跃的账号
export let prevAddress = {
  address: '',
}
export const useWallet = () => {
  const cid = getChainId(config)
  const { setLocalSign } = useContext(SignContext)
  const { disconnect } = useDisconnect()
  const { connectAsync, isPending: connectPending, reset: resetConnnectStatus } = useConnect()
  const { address, isConnected, chainId, isConnecting, isDisconnected } = useAccount()
  const { switchChain, isSuccess, chains, reset: resetSwitchChainStatus } = useSwitchChain()
  const { signMsg } = useSignMsg()
  const { currentNft, setCurrentNft } = useGlobalStore()

  const checkNetwork = chainId == cid
  const isSwitchedAcount = address !== prevAddress.address

  if (address) {
    prevAddress.address = address
  }

  if (isSwitchedAcount) {
    setLocalActiveSign('')
  }

  const connectWallet = async () => {
    resetConnnectStatus()
    try {
      await connectAsync({ connector: injected() })
      signMsg()
      toast(t('not-sign'), { icon: '❕' })
    } catch (error) {}
  }

  const disconnectWallet = () => {
    disconnect()
    toast.success(t('disconnect'))
  }

  // 断开钱包 | 连接钱包
  const handleConnect = () => {
    if (!isConnected) {
      connectWallet()
    } else {
      disconnectWallet()
    }
  }

  // 切换最活跃的用户地址
  const switchActiveSign = useCallback(() => {
    const signs = getSign()
    for (let i = 0; i < signs.length; i++) {
      const userSign = signs[i]
      if (hexadecimalEq(userSign.address, address) && userSign.sign) {
        setLocalActiveSign(userSign.sign)
        return
      }
    }
  }, [address])

  // 切换网络
  const handleSwitchNetwork = useCallback(() => {
    if (!checkNetwork) {
      toast.error(t('switch.network').replace('$$', chains[0].name))
      switchChain({ chainId: cid })
      resetSwitchChainStatus()
    }
  }, [chains, checkNetwork, cid, switchChain, resetSwitchChainStatus])

  // 钱包断开连接，清空对应地址签名
  useEffect(() => {
    if (isDisconnected && prevAddress.address) {
      const newSign = getSign().filter((sign) => {
        return !hexadecimalEq(sign.address, prevAddress.address)
      })
      prevAddress.address = ''
      setLocalSign(newSign)
      setLocalActiveSign('')
      setCurrentNft(undefined)
    }
  }, [isDisconnected, setCurrentNft, setLocalSign])

  // 切换网络
  useEffect(() => {
    // 连接钱包 && 网络错误
    if (isConnected && !checkNetwork) {
      setTimeout(() => {
        handleSwitchNetwork()
      }, 1000)
    }
  }, [isConnected, checkNetwork, handleSwitchNetwork])

  // 监听地址变化，然后更新活跃用户签名
  useEffect(() => {
    if (address) {
      switchActiveSign()
    }
  }, [address, switchActiveSign])

  return {
    chainId,
    address: isConnected ? address : null,
    isConnecting: isConnecting || connectPending,
    checkNetwork,
    switchNetworkSuccess: isSuccess,
    isConnected,
    isSwitchedAcount,
    handleConnect,
    connectWallet,
    disconnect,
    handleSwitchNetwork,
  }
}
