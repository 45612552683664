import { useCallback, useContext, useEffect } from 'react'
import { t } from 'i18next'
import { useAccount, useSignMessage, useVerifyMessage } from 'wagmi'
import toast from 'react-hot-toast'

import { SIGN_MSG } from '@/config/constants'
import { config } from '@/config/wagmi'
import { SignContext } from '@/contexts/sign-context'
import { hexadecimalEq } from '@/utils/address'
import { getActiveSign, setLocalActiveSign } from '@/utils/sign'

export const useSignMsg = () => {
  const { address } = useAccount()
  const { signs, setLocalSign } = useContext(SignContext)
  const {
    data: sign,
    signMessageAsync,
    reset,
    isSuccess,
    isPending,
    isError,
  } = useSignMessage({ config })

  const { status, refetch } = useVerifyMessage({
    address,
    message: SIGN_MSG,
    signature: getActiveSign(),
    query: {
      retry: 1,
    },
  })

  const isAddressSignEq = async () => {
    try {
      const { data, isSuccess, status } = await refetch()
      console.log(status, data, 'SIGN', getActiveSign(), address, SIGN_MSG)

      if (!isSuccess) {
        toast.error(t('sign-failed'))
      }
      return !!data
    } catch (e) {
      toast.error(t('sign-failed'))
      return false
    }
  }

  const signMsg = async () => {
    await signMessageAsync({ message: SIGN_MSG })
  }

  const signA = useCallback(() => {
    if (sign && isSuccess && address) {
      for (let i = 0; i < signs.length; i++) {
        const item = signs[i]
        if (hexadecimalEq(item.address, address) && hexadecimalEq(item.sign, sign)) {
          return
        }
      }
      // 将签名写入本地存储
      setLocalActiveSign(sign)
      setLocalSign([...signs, { address, sign }])
      toast.success(t('sign.success'), { duration: 2_000 })
      reset()
    }
  }, [address, isSuccess, reset, setLocalSign, sign, signs])

  // 确认签名 && 签名写入本地
  useEffect(() => {
    signA()
  }, [signA])

  return {
    isPending,
    isError,
    isSigned: !!getActiveSign(),
    signMsg,
    isAddressSignEq,
  }
}
