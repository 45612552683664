import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { useRouter } from 'next/router'
import { MdMenuOpen } from 'react-icons/md'
import { FaTwitter } from 'react-icons/fa'
import { FaTelegram } from 'react-icons/fa'
import { HiLanguage } from 'react-icons/hi2'
import { useReadContract } from 'wagmi'

import CustomButton from './custom-button'
import { useResponsive } from '@/hooks/use-responsive'
import { fishTokenAddress } from '@/contract/ca'
import { useWallet } from '@/hooks/use-wallet'
import { bankConfig } from '@/contract/bank'
import { fmt } from '@/utils/format'
import { handleShowBg, whiteColorPages } from '@/utils/showBg'
import { LINKS } from '@/config/constants'
import { IoIosArrowDown } from 'react-icons/io'
import { useStorage } from '@/hooks/use-storage'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

interface SocialLinksProps extends React.ComponentProps<'div'> {
  iconSize?: 'small' | 'large' | 'medium'
  iconColor?: string
}


export const Header = (props: Props) => {
  const { pathname } = useRouter()
  const { t, i18n } = useTranslation()
  const { push } = useRouter()
  const { address, isConnecting, handleConnect } = useWallet()
  const { isMobile } = useResponsive()
  const [openMenu, setOpenMenu] = useState(false)
  const { data: balance } = useReadContract({
    ...bankConfig,
    functionName: 'getTokenBalance',
    args: [fishTokenAddress, address!],
  })
  const [langOpen, setLangOpen] = useState(false)
  const langRef = useRef<HTMLDivElement>(null)
  const { setLang } = useStorage()

  const handleWalletText = () => {
    if (address) {
      return fmt.addr(address)
    }

    return t('connect.wallet')
  }

  const list = [
    {
      path: '/',
      title: t('dreamer'),
    },
    {
      path: '/m',
      title: t('moments'),
    },
    {
      path: '/ls', // landtern show 的缩写
      title: t('lantern-show'),
    },
    {
      path: '/dc', // dream city 的缩写
      title: t('dream-city'),
    },
    {
      path: '/about',
      title: t('about-us'),
    },
    // {
    //   path: '/work',
    //   title: t('go.work'),
    // },
    // {
    //   path: '/nft',
    //   title: t('nft.appreciation'),
    // },
    // {
    //   path: '/about',
    //   title: t('game.intro'),
    // },
    // {
    //   path: '/team',
    //   title: t('team.intro'),
    // },
  ]

  const langs = [
    { title: 'English', code: 'en' },
    { title: '中文', code: 'zh' },
  ]

  const computedColor = (page: any) => {
    if (page.path == pathname) {
      return page.path == pathname ? '!text-yellow2' : '!text-black'
    }
    // if (handleShowBg(pathname)) {
    // }

    return pathname === '/m' ? '!text-black' : '!text-white'
  }

  const SocialLinks = (props: SocialLinksProps) => {
    const { className = '', iconSize = 'medium', iconColor = 'inherit' } = props

    return (
      <div className={`${className}`}>
        <IconButton size={iconSize} onClick={() => open(LINKS.x)}>
          <FaTwitter color={pathname.startsWith('/m') ? 'text-black' : iconColor} />
        </IconButton>

        <IconButton size={iconSize} onClick={() => open(LINKS.tg)}>
          <FaTelegram color={pathname.startsWith('/m') ? 'text-black' : iconColor} />
        </IconButton>
        {/* <IconButton size={iconSize} 
        <FaTwitter color={iconColor} />
      </IconButton> */}
      </div>
    )
  }


  const handleHeaderCss = () => {
    if (!whiteColorPages.includes(pathname)) {
      return 'border-b border-gray-300'
    }
    if (pathname === '/dc') {
      return '!fixed top-0 z-[99999] bg-black !bg-opacity-30'
    }

    return ''
  }

  return (
    <div
      className={`relative z-50 bg-opacity-50 w-screen 2xl:px-32 flex justify-between h-[80px] items-center max-sm:px-3 max-sm:h-[60px] max-sm:pl-2 sm:px-10  ${handleHeaderCss()}`}
    >
      <div className="flex items-center">
        {/* 移动端菜单 */}
        {isMobile && (
          <MdMenuOpen
            size={30}
            className={`mr-2 ${handleShowBg(pathname) ? 'text-white' : 'text-black'}`}
            onClick={() => setOpenMenu(!openMenu)}
          />
        )}

        {/* {!isMobile && ( */}
        <h1 className="mr-6 text-2xl font-bold cursor-pointer text-black" onClick={() => push('/')}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/images/logo1.png"
            alt="logo"
            className="relative top-0 h-[50px] max-sm:h-[40px]"
          />
          {/* {t('game.name')} */}
        </h1>
        {/* )} */}
      </div>

      {/* PC端导航 */}
      {!isMobile && (
        <Box sx={{ flexGrow: 1, display: 'flex', mt: 1 }}>
          {list.map((page) => (
            <Button
              key={page.path}
              className={`sm:!text-base ${computedColor(page)} sm:!mr-0 !normal-case`}
              sx={{ color: 'white', display: 'flex', fontSize: 18, mr: 2 }}
              onClick={() => push(page.path)}
            >
              {page.title}
            </Button>
          ))}
        </Box>
      )}

      {/* 链接钱包按钮 */}
      <div className="flex items-center">
        <SocialLinks iconColor="white" className="max-sm:hidden px-2" />
        <div
          className="flex items-center text-white text-xl pr-4 max-sm:pr-2"
          ref={langRef}
          onClick={() => setLangOpen(true)}
        >
          <HiLanguage
            className={`cursor-pointer ${pathname.startsWith('/m') ? 'text-black' : 'text-white'}`}
          />
          <IoIosArrowDown
            className={`transition-all duration-300 ${langOpen && 'rotate-180'} ${
              pathname.startsWith('/m') ? 'text-black' : 'text-white'
            } `}
          />
        </div>
        <Menu
          open={langOpen}
          anchorEl={langRef.current}
          onClose={() => setLangOpen(false)}
          classes={{ root: '!z-[99999]' }}
        >
          {langs.map((lang) => (
            <MenuItem
              key={lang.code}
              selected={i18n.language === lang.code}
              onClick={() => {
                setLangOpen(false)
                i18n.changeLanguage(lang.code)
                setLang(lang.code)
              }}
            >
              {lang.title}
            </MenuItem>
          ))}
        </Menu>
        {/* <span className="text-white mr-2">{formatEther(BigInt(balance ?? 0))} Fish</span> */}
        <CustomButton className="!py-[2px] !px-2" onClick={handleConnect} disabled={isConnecting}>
          {isConnecting ? <CircularProgress size={20} sx={{ mr: 1 }}></CircularProgress> : null}
          {handleWalletText()}
        </CustomButton>
      </div>

      {/* 移动端抽屉 */}
      <Drawer anchor={'left'} open={openMenu} onClose={() => setOpenMenu(false)}>
        <div className="min-w-[65vw] min-h-[100vh] flex flex-col justify-between">
          <div>
            <h1
              className="pt-2 ml-2 text-2xl font-bold cursor-pointer text-black"
              onClick={() => {
                push('/')
                setOpenMenu(false)
              }}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src="/images/logo1.png" alt="logo" className="relative -top-1 h-[50px]" />
              {/* {t('game.name')} */}
            </h1>
            <List>
              {list.map((item) => (
                <ListItem key={item.title} disablePadding>
                  <ListItemButton
                    selected={item.path === pathname}
                    onClick={() => {
                      push(item.path)
                      setOpenMenu(false)
                    }}
                  >
                    <ListItemText
                      primary={item.title}
                      className={`py-1 ${item.path === pathname ? '' : ''}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
          <SocialLinks className="px-3 pb-4" iconSize="large" />
        </div>
      </Drawer>
    </div>
  )
}

export default Header
