import { http, createConfig, fallback, unstable_connector, webSocket } from 'wagmi'
import { arbitrum, goerli } from 'wagmi/chains'
import { injected } from 'wagmi/connectors'

export const dev = createConfig({
  chains: [goerli],
  connectors: [injected()],
  ssr: true,
  transports: {
    // goerli 测试网
    [goerli.id]: fallback([
      http(
        'https://blissful-few-moon.ethereum-goerli.quiknode.pro/83b097d83db0d211158bbbc5e7e573a8ed33ae08/'
      ), // 使用我们自己的RPC
      webSocket(
        'wss://blissful-few-moon.ethereum-goerli.quiknode.pro/83b097d83db0d211158bbbc5e7e573a8ed33ae08/'
      ),
      unstable_connector(injected), // 使用用户钱包RPC
      // ...
      // 在这里可以添加更多RPC节点，防止某个RPC失败后导致整个网页崩溃
    ]),
  },
})

export const prod = createConfig({
  chains: [arbitrum],
  connectors: [injected()],
  ssr: true,
  transports: {
    // ARB主网
    [arbitrum.id]: fallback([
      http(),
      http('https://arb1.arbitrum.io/rpc'),
      unstable_connector(injected),
    ]),
  },
})

export const config = prod
export const chainId = config.chains[0].id
export const confirmations = config.chains[0].id == 42161 ? 2 : 8 // ARB网络需要等待8个区块确认
