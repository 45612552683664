import { create } from 'zustand'

import type { NftInfoRes } from '@/api/nft/nft-type'

interface GlobalStore {
  currentNft: NftInfoRes | undefined
  currentNftId: number
  hasNft: boolean
  isInteracted: boolean
  bgmAudio: HTMLAudioElement | null
  setBgmAudio: (audio: HTMLAudioElement) => void
  setCurrentNft: (currentNft: NftInfoRes | undefined) => void
  setCurrentNftId: (currentNftId: number) => void
  setHasNft: (hasNft: boolean) => void
  setIsInteracted: (isInteracted: boolean) => void
}

export const useGlobalStore = create<GlobalStore>((set) => ({
  isInteracted: false,
  currentNft: undefined,
  currentNftId: -1, // 为了方便使用
  hasNft: false,
  bgmAudio: null,
  setBgmAudio: (bgmAudio) => set({ bgmAudio }),
  setCurrentNft: (currentNft) => {
    const currentNftId = currentNft?.token_id ?? -1
    const hasNft = currentNftId !== -1

    set({ currentNft, currentNftId, hasNft })
  },
  setCurrentNftId: (currentNftId) => set({ currentNftId }),
  setHasNft: (hasNft) => set({ hasNft }),
  setIsInteracted: (isInteracted) => set({ isInteracted }),
}))
