export interface UserSign {
  address: `0x${string}` | ''
  sign: `0x${string}` | ''
}

export const getSign = () => {
  return JSON.parse(localStorage.getItem('big_dreamer.user-signs') || '[]') as UserSign[]
}

export const setLocalSign = (userSigns: UserSign[]) => {
  return localStorage.setItem('big_dreamer.user-signs', JSON.stringify(userSigns))
}

export const getActiveSign = () => {
  return  (localStorage.getItem('big_dreamer.active-signs') ||'') as  `0x${string}`
}

export const setLocalActiveSign = (sign: string) => {
  return localStorage.setItem('big_dreamer.active-signs', sign)
}
