import { baseColor } from '../../tailwind.config'

import type { ThemeOptions } from '@mui/material'

export const getThemeOptions = () => {
  return {
    palette: {
      primary: {
        main: baseColor.primary,
      },
      secondary: {
        main: baseColor.blue,
      },
      info: {
        main: baseColor.info,
      },
      error: {
        main: baseColor.red,
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'black',
            backgroundColor: 'white',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            margin: '0 auto',
            maxWidth: '400px',
            minWidth: '280px',
          },
        },
      },
    },
  } as ThemeOptions
}
