// useSession 为 `true` 则使用 SessionStorage
export const useStorage = (useSession = false) => {
  // namespace
  const ns = (k: string) => 'bigdreamer.' + k

  const get = (k: string) => {
    return useSession ? sessionStorage.getItem(k) : localStorage.getItem(k)
  }

  const set = (k: string, v: string | number) => {
    return useSession ? sessionStorage.setItem(k, String(v)) : localStorage.setItem(k, String(v))
  }

  const remove = (k: string) => localStorage.removeItem(k)

  const clear = () => localStorage.clear()

  const lang = ns('lang')
  const navIdx = ns('nav_index')
  const selectedNftId = ns('selected_nft_id')
  const uuid = ns('uuid')

  return {
    // 通用
    remove,
    clear,

    // 语言
    getLang: () => get(lang),
    setLang: (v: string) => set(lang, v),

    // 底部导航
    getNavIdx: () => get(navIdx),
    setNavIdx: (v: string | number) => set(navIdx, v),

    // NFT 选中
    getNftId: () => get(selectedNftId),
    setNftId: (v: string | number) => set(selectedNftId, v),

    // 聊天 uuid
    getUuid: () => get(uuid) || '',
    setUuid: (v: string | number) => set(uuid, v),
  }
}
