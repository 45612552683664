import type { Config } from 'tailwindcss'

const baseSize = {
  main: '600px',
  secondary: '800px',
  'not-header': 'calc(100vh - 80px)',
  mobile: 'calc(100vw - 20px)',
}

export const baseColor = {
  primary: '#383cef',
  secondary: '#fbf93a',
  info: '#9398A5',
  main: '#ffffff',
  yellow: '#fbf93a',
  yellow2: '#e7ce4e',
  orange: '#F79B39',
  blue: '#383cef',
  green: '#CAFF06',
  red: '#d13322',
}

const config: Config = {
  darkMode: 'class',
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    extend: {
      height: {
        ...baseSize,
      },
      minHeight: {
        ...baseSize,
      },
      maxHeight: {
        ...baseSize,
      },
      width: {
        ...baseSize,
      },
      minWidth: {
        ...baseSize,
      },
      maxWidth: {
        ...baseSize,
      },
      backgroundColor: {
        ...baseColor,
      },
      textColor: {
        ...baseColor,
      },
      borderColor: {
        ...baseColor,
      },
      translate: {
        offset: '4px',
        'offset-n': '-4px',
      },
      boxShadowColor: {
        ...baseColor,
      },
      boxShadow: {
        offset: '3px 4px 0px #000000',
        'offset-primary': `3px 4px 0px ${baseColor.primary}`,
        'offset-sm': `2px 3px 0px #000000`,
      },
    },
  },
  corePlugins: {
    // preflight: false,
  },
}

export default config
