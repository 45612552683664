import { useEffect, useState } from 'react'
import Head from 'next/head'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjsZh from 'dayjs/locale/zh-cn'
import dayjsEn from 'dayjs/locale/en'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider, createTheme } from '@mui/material'
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { watchAccount } from '@wagmi/core'
import duration from 'dayjs/plugin/duration'

import '@/styles/globals.scss'
import i18nConfig from '@/i18n'
import { config } from '@/config/wagmi'
import { getThemeOptions } from '@/theme/material-ui'
import { SignContext } from '@/contexts/sign-context'
import { UserSign, getSign, setLocalActiveSign, setLocalSign } from '@/utils/sign'
import { useStorage } from '@/hooks/use-storage'

import Header from '@/components/header'
import { handleShowBg } from '@/utils/showBg'
import { usePlayer } from '@/hooks/use-player'

import type { AppProps } from 'next/app'
import { useGlobalStore } from '@/store/global'

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale(dayjsZh)

const queryClient = new QueryClient()
let isInteracted = false // 页面是否交互过，首次交互播放 BGM

export default function App({ Component, pageProps }: AppProps) {
  const [pageTitle] = useState('咸鱼梦想家')
  const [isMounted, setIsMounted] = useState(false)
  const [signs, writeSign] = useState<UserSign[]>([])
  const themeConfig = createTheme(getThemeOptions())
  const { i18n } = useTranslation()
  const { getLang } = useStorage()
  const { pathname } = useRouter()
  const { playBgm } = usePlayer()
  const { setBgmAudio } = useGlobalStore()

  const handleSetSign = (userSigns: UserSign[]) => {
    setLocalSign(userSigns)
    writeSign(userSigns)
  }

  const handleLang = () => {
    const cachedLang = getLang()

    if (cachedLang) {
      i18n.changeLanguage(cachedLang)
    }
  }

  useEffect(() => {
    const unwatch = watchAccount(config, {
      onChange: (account, prevAccount) => {
        if (!prevAccount.address) {
          setLocalActiveSign(getSign()?.[0]?.sign || '')
          return
        }
        if (account.address !== prevAccount.address) {
          // 处理切换账户
          location.reload()
          // refetchAll()
        }
      },
    })

    return unwatch
  }, [])

  const handleInteracted = () => {
    window.addEventListener('click', () => {
      if (!isInteracted) {
        isInteracted = true
        setBgmAudio(playBgm())
      }
    })
  }

  useEffect(() => {
    handleLang()
    handleInteracted()
    setIsMounted(true)
    writeSign(getSign())
    window.addEventListener('contextmenu', (e) => e.preventDefault())
  }, [])

  useEffect(() => {
    dayjs.locale(i18n.language === 'zh' ? dayjsZh : dayjsEn)
  }, [i18n.language])

  if (!isMounted) return <></>

  const showBg = handleShowBg(pathname)

  return (
    <WagmiProvider config={config} reconnectOnMount={true}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18nConfig}>
          <ThemeProvider theme={themeConfig}>
            <Head>
              <title>{pageTitle}</title>
              <meta name="keywords" content=" NFT, Web3, AI, Chat, Stephen Chow" />
              <meta name="description" content="全球首个智能生命体NFT，顶级AI，华人之光。" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
              ></meta>
            </Head>
            <SignContext.Provider value={{ signs, setLocalSign: handleSetSign }}>
              {showBg ? (
                <>
                  <video
                    src="/videos/bg.mp4"
                    className="fixed top-0 left-0 z-[-1] brightness-[0.8] w-screen h-screen object-cover max-sm:object-left"
                    muted
                    autoPlay
                    loop
                  />
                  {/* <div className="fixed top-0 left-0 w-screen h-screen backdrop-filter backdrop-blur-[4px] pointer-events-none"></div> */}
                </>
              ) : null}
              <Header />
              <Component {...pageProps} />
            </SignContext.Provider>
            <Toaster />
          </ThemeProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
