export const MAX_VALUE =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const INVITE_LINK = ''

export const SIGN_MSG = 'Signature Verification'

export const MY_SIGN =
  '0xd92d811fbfb128844c44346007e61f10f0ea909691c15e9c0339ec0686973d0c31d73e12e65cd9df6568a12d52eea763c3f31766deed74dbb1de2c9cc091a41c1c'

export const baseURL = 'https://beta.bigdreamer.xyz'

export const INVITE_LINKS = {
  index: `${baseURL}?i=`,
  chat: `${baseURL}/chat/`,
}

export const LINKS = {
  x: 'https://twitter.com/BigDreamerFi',
  tg: 'https://t.me/bigdreamerx',
}
