import { Address } from 'viem'

export const fmt = {
  percent(p: number | string | undefined, { fixed = 0, symbol = '', toNum = false } = {}) {
    if (!p) return `0${symbol}`

    const fixNoRound = Math.floor(Number(p) * 100)
    const result = `${fixNoRound}${symbol}`

    return toNum ? Number(result) : result
  },
  addr(addr: Address | string | undefined | null, preLen = 4, sufLen = 4) {
    if (!addr) return ''

    return addr.slice(0, preLen) + '...' + addr.slice(-sufLen)
  },
  ellipsis(str: string | undefined, max = 6) {
    if (!str) return

    const isOvered = str.length > max
    const formatted = str.slice(0, max)

    return isOvered ? `${formatted}...` : str
  },
}
